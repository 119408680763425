<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-label class="fw-600 fs-3 pl-2">{{ $t('payment_summary') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5">
        <div class="pb-5 d-flex align-center justify-center flex-column">
          <ion-icon class="f-icon" :class="paymentStatus.color" :icon="paymentStatus.icon"></ion-icon>
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatus.color">
            {{ paymentStatus.headerLabel }}</ion-label
          >
        </div>
        <ion-grid class="w-90 rounded-sm shadow-sm px-3 pt-3 bg-white">
          <ion-row v-for="(item, i) in renderData" class="mb-2" :key="i">
            <ion-col>
              <ion-label class="fw-500 text-gray-700">{{ item.label }}</ion-label>
              <ion-row class="mt-2" v-for="(invoice, i) in item.invoices" :key="i">
                <ion-col>
                  <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
                </ion-col>
                <ion-col size="auto" push="0.6">
                  <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-600 text-gray-700"> {{ item.value }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row class="mb-2">
            <div class="box mb-2">
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-col>
                  <ion-row>
                    <ion-label class="ml-2 mt-1 text-gray-700 fw-700">{{ $t('note_only') }} </ion-label>
                  </ion-row>
                  <ion-row>
                    <ion-text class="ml-2 mt-2 mb-2 text-gray-700">{{ notes || '-' }} </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </div>
          </ion-row>
          <ion-row class="mb-2">
            <ion-col>
              <ion-label class="text-primary-green-700 fw-500">{{ $t('applied_credits') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-600 text-gray-700">
                {{ !totalBalanceUsed ? '-' : priceFormat(totalBalanceUsed) }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="fw-600 text-gray-700">{{ $t('total') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-500" :class="paymentStatus.color"> {{ paymentStatus.label }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700"> {{ priceFormat(totalPaidAmount) }}</ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-button class="mb-2 mt-2" @click="$emit('backToInvoice')" expand="block" color="primary">
              <ion-label class="text-capitalize"> {{ $t('back_to_invoice') }} </ion-label>
            </ion-button>
            <ion-button class="mb-2" @click="$emit('backToHome')" fill="outline" expand="block">
              <ion-label class="text-capitalize"> {{ $t('back_to_home') }} </ion-label>
            </ion-button>
            <ion-button
              v-if="paymentStatus.value === PENDING_APPROVAL"
              class="mb-4"
              @click="onCancelPayment"
              fill="outline"
              expand="block"
              color="danger"
            >
              <ion-label class="text-capitalize"> {{ $t('cancel_payment') }} </ion-label>
            </ion-button>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-loading :is-open="loadingCancelling" cssClass="custom-loading" spinner="crescent"> </ion-loading>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { INVOICE_PAYMENT_TYPE, PAYMENT_HISTORY_LIST_STATUS } from '@/modules/shared/constants/';
import { cancelManualPayment } from '@/modules/shared/services/graphql';
import { toastError, useAlert } from '@/modules/shared/utils/';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils';
import { useBackButton } from '@ionic/vue';
import { alertCircleOutline, checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ModalPaymentSummary',
  emits: ['backToInvoice', 'backToHome', 'cancelPayment'],
  props: {
    paymentSummary: {
      type: Object,
      default: () => {}
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    invoice: {
      type: Array,
      default: () => []
    },
    paymentType: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const storage = inject('$storage');
    const router = useRouter();
    const payloadCancel = ref({ tenantId: null, customerId: null });
    const { isValidDate, formatDateMonthYear, formatDateTime } = useDateFormatter();
    const { createAlertTwoAction, createAlert } = useAlert();
    const { BANK_TRANSFER, CASH_DEPOSIT } = INVOICE_PAYMENT_TYPE;
    const { PAID, REJECTED, CANCELLED, PENDING_APPROVAL } = PAYMENT_HISTORY_LIST_STATUS;
    const notes = ref(props.paymentSummary?.notes) || '-';
    const totalBalanceUsed = ref(props.paymentSummary?.totalCustomerBalanceUsed) || 0;
    const totalPaidAmount = ref(props.paymentSummary?.totalPaidAmount);
    const paymentMethodVariants = {
      [BANK_TRANSFER]: t('bank_transfer'),
      [CASH_DEPOSIT]: t('cash_deposit')
    };
    const paymentStatusVariants = [
      {
        headerLabel: t('payment_pending'),
        icon: alertCircleOutline,
        color: 'text-primary-orange-500',
        value: PENDING_APPROVAL,
        label: t('pending')
      },
      {
        headerLabel: t('payment_success'),
        icon: checkmarkCircleOutline,
        color: 'text-primary-green-600',
        value: PAID,
        label: t('paid')
      },
      {
        headerLabel: t('payment_failed'),
        icon: closeCircleOutline,
        color: 'text-error-600',
        value: CANCELLED,
        label: t('order.cancelled')
      },
      {
        headerLabel: t('payment_failed'),
        icon: closeCircleOutline,
        color: 'text-error-600',
        value: REJECTED,
        label: t('rejected')
      }
    ];
    const labelPaymentMethod = paymentMethodVariants[props.paymentType];
    const invoices = props.invoice.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: priceFormatter(props.currencySymbol, invoice.outstandingAmount)
      };
    });
    const dataBank = [
      {
        label: `${t('invoice_list')}:`,
        value: '',
        invoices
      },
      {
        label: `${t('payment_method')}:`,
        value: labelPaymentMethod
      },
      {
        label: `${t('transaction_date')}:`,
        value: isValidDate(props.paymentSummary?.transactionDate)
          ? formatDateMonthYear(props.paymentSummary?.transactionDate)
          : `-`
      },
      {
        label: `${t('payment_value_date')}:`,
        value: isValidDate(props.paymentSummary?.valueDate)
          ? formatDateMonthYear(props.paymentSummary?.valueDate)
          : `-`
      },
      {
        label: `${t('payment_time')}:`,
        value: formatDateTime(new Date())
      }
    ];
    const dataCash = [
      {
        label: `${t('invoice_list')}:`,
        value: '',
        invoices
      },
      {
        label: `${t('payment_method')}:`,
        value: labelPaymentMethod
      },
      {
        label: `${t('deposit_date')}:`,
        value: isValidDate(props.paymentSummary?.transactionDate)
          ? formatDateMonthYear(props.paymentSummary?.transactionDate)
          : `-`
      },
      {
        label: `${t('payment_time')}:`,
        value: formatDateTime(new Date())
      }
    ];
    const renderData = props.paymentType === BANK_TRANSFER ? dataBank : dataCash;
    const loadingCancelling = ref(false);
    const setLoadingCancelling = (state) => (loadingCancelling.value = state);
    const paymentStatus = ref(paymentStatusVariants[0]);
    const backToInvoice = () => {
      emit('backToInvoice');
    };
    const onActionCancel = async () => {
      setLoadingCancelling(true);
      try {
        const { data, errors } = await apolloClient.mutate({
          mutation: cancelManualPayment,
          variables: {
            batchPaymentId: props.paymentSummary?.details?.id
          }
        });
        if (errors || !data.cancelManualPayment) {
          setLoadingCancelling(false);
          createAlert(t('unable_to_cancel'), t('payment_status_changed'), backToInvoice);
        } else {
          setLoadingCancelling(false);
          toastError(t('cancel_payment_success'));
          emit('backToInvoice');
        }
      } catch (error) {
        setLoadingCancelling(false);
        createAlert(t('errorTitle'), error.message);
      }
    };
    const onCancelPayment = () => {
      createAlertTwoAction(
        t('cancel_submitted_payment'),
        t('cancel_submitted_payment_desc'),
        t('confirm_cancel_payment'),
        onActionCancel,
        t('close'),
        'alert-cancel-payment-history'
      );
    };

    const getStorage = async () => {
      await storage.getUser().then(({ tenant }) => {
        payloadCancel.value.tenantId = tenant.id;
      });
      await storage.getSelectedCompany().then(({ id }) => {
        payloadCancel.value.customerId = id;
      });
    };
    useBackButton(10, () => {
      router.push('/b2b/main/home');
    });
    onMounted(() => {
      getStorage();
    });
    return {
      PENDING_APPROVAL,
      paymentStatus,
      renderData,
      alertCircleOutline,
      totalBalanceUsed,
      totalPaidAmount,
      notes,
      priceFormat: (price) => priceFormatter(props.currencySymbol, price),
      onCancelPayment,
      loadingCancelling
    };
  }
});
</script>
<style lang="scss" scoped>
.f-icon {
  font-size: 3rem;
}

ion-button {
  width: 97%;
  margin: 0 auto;
  --border-radius: 10px;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
  width: 100%;
}
.rounded-sm {
  border-radius: 7px;
}
</style>
