import dayjs from 'dayjs';

import { useGetStorageData } from '..';
export const useMapDatePicker = () => {
  const { selectedLanguage } = useGetStorageData();
  const setLang = async () => {
    const lang = await selectedLanguage.value;
    dayjs.locale(lang);
  };
  setLang();
  const locale = dayjs.locale();
  const monthMap = () => {
    return Array.from({ length: 12 }, (_, i) => i + 1).map((i) => ({
      text: dayjs()
        .month(i - 1)
        .format('MMM'),
      value: i < 10 ? `0${i}` : i.toString()
    }));
  };

  const dayMap = () => {
    return Array.from({ length: 31 }, (_, i) => i + 1).map((i) => ({
      text:
        i < 10
          ? `0${i} ${!locale.includes('zh') ? `` : `日`}`
          : `${i.toString()} ${!locale.includes('zh') ? `` : `日`}`,
      value: i < 10 ? `0${i}` : i.toString()
    }));
  };

  const yearMap = () => {
    const years = [];
    for (let i = 2022; i <= 2050; i++) {
      years.push({
        text: `${i.toString()} ${!locale.includes('zh') ? `` : `年`}`,
        value: i.toString()
      });
    }
    return years;
  };

  const getMonth = monthMap();
  const getDay = dayMap();
  const getYear = yearMap();
  return {
    getMonth,
    getDay,
    getYear
  };
};
